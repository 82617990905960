* {
    box-sizing: border-box
}

html{background-color: #F1F5F9 !important;}

body {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    padding: 0;
    color: #474747;
}

a {
    text-decoration: none;
    color: black
}

input[type='text'], input[type='password'] {
    border: 1px solid #CCC;
    background-color: #F6F6F6;
    font-size: 16px;
    padding: 0.5em 0.4em;
    width: 300px
}

.btn-main {
    position: relative;
    outline: none;
    width: 100%
}

.btn-main {
    background-color: #FF823D;
    background-repeat: no-repeat;
    background-position: top 13px right 30px;
    border: none;
    padding: 0.5em 1em;
    margin-top: 10px;
    transition: all 200ms;
    cursor: pointer;
    font-size: 18px;
    color: #000;
    overflow: hidden
}

.btn-main:hover {
    background-color: #ff7d22
}

.btn-main:hover:after {
    right: 0
}

.btn-main:active {
    transform: scale(0.98)
}

#LogIn a {
    padding: 0.5em 1em;
    display: block;
    border: 1px solid transparent;
    margin-top: 20px;
    transition: border 150ms;
    text-align: center;
    font-size: 14px;
    position: relative;
    outline: none;
    width: 100%;

    &:hover {
        border: 1px solid #CCC
    }
}

#Reg {
    margin: 0 auto 100px;
    width: 310px;
    text-align: left
}

#Upper {
    height: 120px;
    margin-bottom: 20px;
    padding-left: 10px;
    text-align: right;
    font-size: 0.8em
}

img.logo {
    margin: 10px 0 0 10px;
    float: left;
    display: none;
}

#langWrapper {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;

    .lang {
        padding: 0.4em 0.7em;
        border-right: 1px solid #CCC;
        display: block;
    }

    .lang:first-child {
        border-right: none
    }

    .lang:hover {
        background-color: #F0F0F0
    }
}

#versionWrapper {
    @media (max-width: 600px) {
        display: none;
    }
}

#kirjaudusis {
    font-size: 120%;
    font-weight: bold;
    margin-top: 1.5em;
}

#wrong {
    color: red;
    font-weight: bold
}

#release_notes_link {
    font-size: 12px;
    margin-top: 15px;
    margin-right: 5px
}

#firstbutton {
    display: inline;
    width: 800px
}

#secondbutton {
    display: inline;
    width: 800px
}

.build_type_DEV {
    color: red;
    margin-right: 5px
}

.build_type_TEST {
    color: red;
    margin-right: 5px;
}

.build_type_STAGING {
    color: red;
    margin-right: 5px;
}

.build_type_PRODUCTION {
    display: none
}

.form-line {
    margin-bottom: 0.7em
}

.g-recaptcha {
    margin-top: 30px;
    width: 400px
}

select {
    width: 100%;
    padding: 0.4em 1em;
    border: 1px solid #CCC
}

.col-1-2 {
    width: 50%;
    float: left;
    padding: 35px
}

.col-2-3 {
    width: 66.666%;
    float: left;
    padding: 35px
}

.col-1-3 {
    width: 33.333%;
    float: left;
    padding: 35px
}

@media (max-width: 700px) {
    [class^="col-"] {
        width: 100%
    }
}

#registration-wrapper {
    width: 90%;
    max-width: 900px;
    overflow: hidden;
    margin: 0 auto 50px
}

#reg-form-wrapper {
    background-color: #F7F7F7
}

#reg-form-wrapper input {
    background-color: #FFF;
    width: 100%;
    padding: 0.5em 0.4em
}

#reg-desc-wrapper {
    padding: 0;
    padding-right: 6%
}

#reg-desc-wrapper img {
    margin: 2em 0;
    max-width: 100%;
    height: auto
}

.centered {
    text-align: center
}

.title-underline {
    border-bottom: 3px solid #EB613E;
    padding-bottom: 0.4em;
    margin-bottom: 2.5em;
    display: inline-block
}


#loginBtn {
    &:disabled {
        pointer-events: none;
        opacity: 0.7;
    }
}

.login-desktop__wrapper, .login-mobile__wrapper {
    margin: auto;
    padding: 20px;
    width: 340px;
    background-color:#FFF;
}

.login-mobile__wrapper {
    display: flex;
    flex-direction: column;

    input[type=text] {
        font-size: 18px;
    }

    img {
        width: 100%;
        height: auto;
    }

    #forgotPasswordBtn {
        margin-top: 30px;
    }

    .login__logo {
        margin-bottom: 40px;
    }

    .login__label {
        font-size: 18px;
        margin-bottom: 3px;
    }

    a {
        text-align: center;
    }
}

.login__logo-tagline {
    font-weight: bold;
    text-align: right;
    position: relative;
    top: -10px;
}

